import Vue from "vue"
import VueRouter from "vue-router"
import Auth from "@/api/services/Auth.js"

import store from "@/store"

Vue.use(VueRouter)

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

const isLogged = async (to, from, next) => {
    const userAgent = await navigator.userAgent.toLowerCase()
    const isMobile =
        await userAgent.includes("android") || userAgent.includes("iphone")
    if (isMobile) {
        next("/not-supported")
        return
    }
    // verificar se tem sessão ou cookie com id access token
    let accessToken = getCookie("access_token")

    if (accessToken) {
        // se tiver
        store.commit("portal/setLogin", {
            access: accessToken,
            // refresh: refreshToken,
        });
        if (!store.state.portal.profile.name) {
            var me = await Auth.me()
            store.commit("portal/setProfile", me.data.results[0])
            var me = await Auth.me()
            await store.commit("portal/setProfile", me.data.results[0])
            next()
            return
        }
        next()
        return
    }
    window.location.href = window.location.origin + '/auth/login/'
}
const homeRedirect = async (to, from, next) => {
    const userAgent = await navigator.userAgent.toLowerCase()
    const isMobile = await  userAgent.includes("android") || userAgent.includes("iphone")
    if (isMobile) {
        // Do something if the user is on a mobile device
        next("/not-supported")
        return
    }
    // verificar se tem sessão ou cookie com id access token
    let accessToken = getCookie("access_token")

    if (accessToken) {
        // se tiver
        await store.commit("portal/setLogin", {
            access: accessToken,
            // refresh: refreshToken,
        });
        if (!store.state.portal.profile.name) {
            var me = await Auth.me()
            await store.commit("portal/setProfile", me.data.results[0])
            next("/start")
            return
        }
    }
    window.location.href = window.location.origin + '/auth/login/'
}

const authRedirect = (to, from, next) => {
    store.commit("portal/setLogin", {
        access: to.params.token,
        refresh: to.params.refresh,
    })
    Auth.me()
        .then((response) => {
            store.commit("portal/setProfile", response.data.results[0])
            if (to.query.url) {
                next(to.query.url)
            } else {
                next("/")
            }
        })
        .catch((error) => {
            Auth.logout()
            next("/")
        })
}
const startRedirect = (to, from, next) => {
    next("/home")
}

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: "/",
            name: "index",
            beforeEnter: homeRedirect,
            meta: {
                layout: "full",
            },
        },
        {
            path: "/start",
            name: "index-2",
            beforeEnter: startRedirect,
            meta: {
                layout: "full",
            },
        },
        {
            path: "/sso/:token/:refresh",
            name: "sso",
            beforeEnter: authRedirect,
            props: true,
        },
        {
            path: "/home",
            name: "home",
            component: () =>
                import(
                    /* webpackChunkName: "home" */ "@/views/portal/Home.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Home",
                breadcrumb: [
                    {
                        text: "Home",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/reports/finance",
            name: "report-finance",
            component: () =>
                import(
                    /* webpackChunkName: "report-finance" */ "@/views/reports/Finance.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Finance",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: false,
                    },
                    {
                        text: "Finance",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/mileage",
            name: "forms-mileage",
            component: () =>
                import(
                    /* webpackChunkName: "forms-mileage" */ "@/views/kms/Kms.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "My Mileage",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "My Mileage",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/mileage/:id/:month/:year",
            name: "forms-mileage-detail",
            component: () =>
                import(
                    /* webpackChunkName: "forms-mileage-detail" */ "@/views/kms/Kms.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Mileage Detail",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "People's Mileage",
                        to: "/forms/peoples-mileage",
                        active: false,
                    },
                    {
                        text: "Mileage Detail",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/peoples-mileage",
            name: "forms-peoples-mileage",
            component: () =>
                import(
                    /* webpackChunkName: "forms-peoples-mileage" */ "@/views/kms/PeoplesKms.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "People's Mileage",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "People's Mileage",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/expenses",
            name: "forms-expenses",
            component: () =>
                import(
                    /* webpackChunkName: "forms-expenses" */ "@/views/expenses/Expenses.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "My Expenses",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "My Expenses",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/expenses/:id/:month/:year",
            name: "forms-expenses-detail",
            component: () =>
                import(
                    /* webpackChunkName: "forms-expenses-detail" */ "@/views/expenses/Expenses.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Expense Detail",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "People's Expenses",
                        to: "/forms/peoples-expenses",
                        active: false,
                    },
                    {
                        text: "Expense Detail",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/forms/peoples-expenses",
            name: "forms-peoples-expenses",
            component: () =>
                import(
                    /* webpackChunkName: "forms-peoples-expenses" */ "@/views/expenses/PeoplesExpenses.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "People's Expenses",
                breadcrumb: [
                    {
                        text: "Forms",
                        active: false,
                    },
                    {
                        text: "People's Expenses",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/reports/mileage/",
            name: "reports-mileage",
            component: () =>
                import(
                    /* webpackChunkName: "reports-mileage" */ "@/views/kms/KmsReport.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Mileage",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: false,
                    },
                    {
                        text: "Mileage",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/reports/mileage/:date",
            name: "reports-mileage-items",
            component: () =>
                import(
                    /* webpackChunkName: "forms-mileage-items" */ "@/views/kms/KmsReportItems.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "People's mileage report",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: false,
                    },
                    {
                        text: "Mileage",
                        to: "/reports/mileage",
                        active: false,
                    },
                    {
                        text: "People's mileage report",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/reports/expense",
            name: "reports-expense",
            component: () =>
                import(
                    /* webpackChunkName: "reports-expense" */ "@/views/expenses/ExpensesReport.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Expense",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: false,
                    },
                    {
                        text: "Expense",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/reports/expense/:date",
            name: "reports-expense-items",
            component: () =>
                import(
                    /* webpackChunkName: "forms-expense-items" */ "@/views/expenses/ExpenseReportItems.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "People's expense report",
                breadcrumb: [
                    {
                        text: "Reports",
                        active: false,
                    },
                    {
                        text: "Expense",
                        to: "/reports/expense",
                        active: false,
                    },
                    {
                        text: "People's expense report",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/settings/permissions",
            name: "settings-permissions",
            component: () =>
                import(
                    /* webpackChunkName: "settings-permissions" */ "@/views/portal/Permissions.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "User's Permissions",
                breadcrumb: [
                    {
                        text: "Settings",
                        active: false,
                    },
                    {
                        text: "User's Permissions",
                        active: true,
                    },
                ],
            },
        },
        {
            path: "/settings/permissions/:id",
            name: "manage-permissions",
            component: () =>
                import(
                    /* webpackChunkName: "manage-permissions" */ "@/views/portal/ManagePermissions.vue"
                ),
            beforeEnter: isLogged,
            meta: {
                pageTitle: "Manage Permissions",
                breadcrumb: [
                    {
                        text: "Settings",
                        active: false,
                    },
                    {
                        text: "User's Permissions",
                        to: "/settings/permissions",
                        active: false,
                    },
                    {
                        text: "Manage Permissions",
                        active: true,
                    },
                ],
            },
        },
        // {
        //     path: "/my-account",
        //     name: "my-account",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "my-account" */ "@/views/portal/UserEditTabAccount.vue"
        //         ),
        //     beforeEnter: isLogged,
        //     meta: {},
        // },
        // {
        //     path: "/auth/login/",
        //     name: "login",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "auth-login" */ "../views/authentication/Login.vue"
        //         ),
        //     meta: {
        //         layout: "full",
        //     },
        // },
        // {
        //     path: "/forgot-password",
        //     name: "forgot-password",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "auth-forgot-password" */ "@/views/authentication/ForgotPassword.vue"
        //         ),
        //     meta: {
        //         layout: "full",
        //     },
        // },
        // {
        //     path: "/reset-password/:token",
        //     name: "reset-password",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "auth-reset-password" */ "@/views/authentication/ResetPassword.vue"
        //         ),
        //     meta: {
        //         layout: "full",
        //     },
        //     props: true,
        // },
        // {
        //     path: "/register",
        //     name: "register",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "auth-register" */ "@/views/authentication/Register.vue"
        //         ),
        //     meta: {
        //         layout: "full",
        //     },
        // },
        {
            path: "/not-supported",
            name: "not-supported",
            component: () =>
                import(
                    /* webpackChunkName: "error-404" */ "@/views/error/NotSupported.vue"
                ),
            meta: {
                layout: "full",
            },
        },
        // {
        //     path: "/error-404",
        //     name: "error-404",
        //     component: () =>
        //         import(
        //             /* webpackChunkName: "error-404" */ "@/views/error/Error404.vue"
        //         ),
        //     meta: {
        //         layout: "full",
        //     },
        // },
        // {
        //     path: "*",
        //     redirect: "error-404",
        // },
    ],
})


// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg")
    if (appLoading) {
        appLoading.style.display = "none"
    }
})

export default router
